<template>
  <div class="wrap">
    <van-row type="flex">
      <van-col span="24">
        <div class="top">
          <div class="title">
            <span>Hello</span>
            <span>欢迎来到众惠其珹</span>
          </div>

          <div class="form">
            <van-form ref="loginForm"  :submit-on-enter="false" :validate-first="true">
              <van-cell-group :border="false">
                <van-cell>
                  <van-field
                      center
                      name="phone"
                      v-model="phone"
                      @focus="keyboardShow = true"
                      @blur="keyboardShow = false"
                      type="tel"
                      placeholder="请输入手机号"
                      :rules="[{ validator: validatorPhone, message: '手机号码有误' }]"
                  >
                    <template #left-icon>
                      <van-icon :name="require('@/assets/images/login/phone.png')" size="20"></van-icon>
                    </template>
                  </van-field>
                </van-cell>
                <van-cell>
                  <van-field
                      v-model="smsCode"
                      @focus="keyboardShow = true"
                      @blur="keyboardShow = false"
                      type="number"
                      center
                      name="smsCode"
                      clearable
                      maxlength=6
                      :rules="[{ validator: validatorCode, message: '验证码有误' }]"
                      placeholder="请输入验证码">
                    <template #left-icon>
                      <van-icon :name="require('@/assets/images/login/lock.png')" size="20"/>
                    </template>
                    <template #button>
                      <van-button size="small" round plain class="sms" @click="getSmsCode">
                        {{ verifyCodeLimitSeconds > 0 ? (verifyCodeLimitSeconds + ' 秒后重试') : '获取' }}
                      </van-button>
                    </template>
                  </van-field>
                </van-cell>
                <div class="agreement-box">
                  <van-checkbox v-model="checked">
                    <img class="img-icon"
                         slot="icon"
                         slot-scope="props"
                         :src="props.checked ? require('@/assets/images/login/checked.png') : require('@/assets/images/login/unChecked.png')">
                  </van-checkbox>
                  <div class="agreement-desc">
                    <span class="desc" @click="checked = !checked">我已经同意并阅读众惠其珹</span>
                    <a
                        href="http://portal.zhqcmall.com/pages/userRegisterAgreement.html">《服务协议》</a>和<a
                      href="http://portal.zhqcmall.com/pages/privacy.html">《隐私政策》</a></div>
                </div>
              </van-cell-group>
            </van-form>
          </div>
          <div class="button">
            <van-button color="#FF2020" block  round :disabled="!couldLogin" @click="login">登录</van-button>
          </div>

        </div>
      </van-col>
      <van-col span="24">
        <div class="bottom">
          <van-image :src="require('@/assets/images/login/logo.png')" width="57" height="66" class="logo"></van-image>
        </div>
      </van-col>
    </van-row>
    <loading-overlay :loading="loading"/>
  </div>
</template>

<script>
import Vue from "vue";
import {Col, Row, Image, Button, Form, Field, Cell, CellGroup, Icon, Toast, Checkbox} from "vant";
import {getOssSmsCode, loginTo} from "@/api/login";
import {getInvitationCode, getUserToPath, setAccessToken, setCustomWeChatAccount} from "@/utils/helper";
import LoadingOverlay from "@/components/LoadingOverlay";
Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(Button)
Vue.use(Form)
Vue.use(Field)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Icon)
Vue.use(Checkbox)
export default {
  name: "Reg",
  components: {LoadingOverlay},
  data() {
    return {
      loading: false,
      phone: "", // 手机号
      smsCode: "", // 验证码
      time: 30,
      checked: false, // 同意协议
      verifyCodeLimitSeconds: 0, // 倒计时时间
      keyboardShow: false,
    }
  },
  methods:{
    validatorPhone(val) {
      return /1\d{10}/.test(val);
    },
    validatorCode(val) {
      return /^\d{6}$/g.test(val)
    },
    getSmsCode() {
      if(this.loading){
        return false
      }
      if (this.verifyCodeLimitSeconds > 0) {
        return false;
      }
      if (!this.validatorPhone(this.phone)) {
        Toast('请填写正确的手机号');
        return false;
      }

      this.$refs.loginForm.resetValidation('smsCode')

      this.loading = true
      getOssSmsCode(this.phone).then(res => {
        this.$refs.loginForm.resetValidation('smsCode')
        this.loading = false
        if (res.code === 200) {
          this.lastSecondSetInterval();
        } else {
          Toast.fail(res.message || '短信验证码发送失败，请稍候重试');
        }
      }, err => {
        this.loading = false
        Toast.fail('短信验证码发送失败，请稍候重试');
        console.log(err)
      })
    },

    // 倒计时
    lastSecondSetInterval() {
      this.verifyCodeLimitSeconds = this.time;
      let timer = setInterval(() => {
        let lastSeconds = --this.verifyCodeLimitSeconds;
        this.verifyCodeLimitSeconds = lastSeconds < 0 ? 0 : lastSeconds;
        if (lastSeconds <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    },

    // 登录
    login() {

      if (!this.checked) {
        Toast("请阅读并同意众惠其珹《服务协议》和《隐私政策》");
        return false;
      }

      if(!this.couldLogin){
        return  false
      }
      this.loading = true
      loginTo(this.phone, this.smsCode, getInvitationCode()).then(res => {
        this.loading = false
        if (res.code === 200) {
          setAccessToken(res.data.token)
          setCustomWeChatAccount(res.data.weChatAccount)
          if(res.data.memberCompanyId){
            this.$router.replace(getUserToPath())
          }else{
            this.$router.replace({name:'bindCompany'})
          }

        } else {
          Toast.fail(res.message || '验证码错误');
        }
      }, err => {
        this.loading = false
        console.log(err);
        Toast.fail(err.message || '登录失败，请稍候再试');
      })
    }
  },
  computed:{
    couldLogin(){
      return this.checked && this.validatorPhone(this.phone) && this.validatorCode(this.smsCode)
    },
  }
}
</script>

<style scoped lang="sass">
.wrap
  background-color: #fff
  padding: 0 45px
  box-sizing: border-box
  height: 100vh

.top
  display: flex
  flex-direction: column
  height: calc(100vh - 30px - 66px)
  min-height: 400px!important
  justify-content: space-around
  .title
    font-size: 24px
    font-weight: 600
    color: #333333
    display: flex
    flex-direction: column
  .form
    .van-cell
      padding-left: 0
      padding-right: 0
    .sms
      border: none
      color: #ff0000
      font-size: 13px
      font-weight: 400
    .agreement-box
      margin-top: 10px
      display: flex
      align-items: center
      .img-icon
        width: 20px
        height: 20px
        margin-right: 5px
      .agreement-desc
        margin-left: 5px
        font-size: 12px
        color: #999999
        a
          color: #136AED
        .desc
          cursor: pointer






  //.agree
  //  display: flex
  //  flex-direction: row
  //  .agree-custom
  //    width: unset
      //.van-field__control--custom
      //  display: block




  .button
    .van-button__text
      font-size: 15px



.bottom
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding-bottom: 30px

</style>

<style lang="sass">
//.agree
//  .agree-custom
//    .van-field__control--custom
//      display: block
</style>
